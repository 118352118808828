import React, { useState } from 'react';
import Header from './Header';
import NavBar from './NavBar';
import { Box } from '@mui/material';
import Reviews from './Reviews';
import About from './About';
import Plans from './Plans';
import FAQ from './FAQ';
import GetInTouch from './GetInTouch';
import Footer from './Footer';
import ThankYouModal from './ThankYouModal';

function App() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoadingError, setLoadingError] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: '#121221',
      }}
    >
      <NavBar></NavBar>
      <Header></Header>
      <Reviews></Reviews>
      <About></About>
      <Plans></Plans>
      <FAQ></FAQ>
      <GetInTouch setLoadingError={setLoadingError} setModalOpen={setModalOpen}></GetInTouch>
      <Footer></Footer>

      <ThankYouModal
        isLoadingError={isLoadingError}
        setLoadingError={setLoadingError}
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
      ></ThankYouModal>
    </Box>
  );
}

export default App;
