import React from 'react';

import { Box, Link, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LINKS, SOCIAL_LINKS } from '../NavBar/NavBar.constants';

import J from './assets/J.svg';
import T from './assets/T.svg';

function Footer() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: isMobile ? '0 20px' : '0 30px',
        paddingBottom: '60px',
      }}
    >
      <Box
        sx={{
          maxWidth: '1216px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            marginBottom: '60px',
          }}
        >
          <img src={J} alt="logo start"></img>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginLeft: '15px',
              '& div': {
                height: '15px',
                background: 'rgba(205, 207, 246, 0.20)',
              },
            }}
          >
            <Box marginRight={'15px'}></Box>
            <Box marginRight={'-5px'}></Box>
            <Box marginRight={'-5px'}></Box>
          </Box>
          <img src={T} alt="logo end"></img>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <Box sx={{}}>
            <Typography
              variant="h1"
              sx={{
                fontFamily: 'Dela400',
                fontSize: '14px',
                color: 'white',
                lineHeight: 'normal',
                marginBottom: isMobile ? '30px' : '20px',
                textAlign: isMobile ? 'center' : undefined,
              }}
            >
              {t('footer.copyright')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '10px 30px',
                justifyContent: isMobile ? 'center' : undefined,
                flexWrap: 'wrap',

                '& a': {
                  fontFamily: 'Dela400',
                  color: '#FFF',
                  fontSize: '14px',
                  textDecoration: 'none',
                  transition: 'all 0.3s ease',
                  opacity: 0.7,
                  '&:hover': {
                    opacity: 1,
                  },
                },
              }}
            >
              {LINKS.map((link) => (
                <Link key={link.translation} href={link.href}>
                  {t(link.translation)}
                </Link>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column-reverse' : 'column',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Dela400',
                fontSize: '14px',
                color: 'white',
                opacity: 0.7,
                lineHeight: 'normal',
                textAlign: isMobile ? 'center' : 'right',
                marginBottom: isMobile ? 0 : '14px',
                cursor: 'pointer',

                '&:hover': {
                  opacity: 1,
                },
              }}
              onClick={() => {
                window.open('/terms&conditions', '_blank');
              }}
            >
              {t('footer.termsAndConditions')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'flex-end',
                gap: '20px',
                margin: isMobile ? '30px 0' : undefined,
                '& a': {
                  transition: 'all 0.3s ease',
                  opacity: 0.7,
                  '&:hover': {
                    opacity: 1,
                  },
                },
              }}
            >
              {SOCIAL_LINKS.map((link) => (
                <Link target="_blank" rel="noopener" key={link.alt} href={link.href}>
                  <img src={link.src} alt={link.alt} />
                </Link>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
