import React, { useState } from 'react';
import close from './assets/close.svg';
import logo from '../../assets/logo.svg';
import burger from './assets/burger.svg';
import arrow_up from './assets/arrow_up.svg';
import { Box, Button, Dialog, Link, Slide, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LANGUAGES, LINKS, LanguageObj, SOCIAL_LINKS } from './NavBar.constants';
import { TransitionProps } from '@mui/material/transitions';
import { APP_LINKS } from '../../utils/constants';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function BurgerMenu({
  selectedLanguage,
  setSelectedLanguage,
}: {
  selectedLanguage: LanguageObj;
  setSelectedLanguage: (lang: LanguageObj) => void;
}) {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isLanguageMenuOpen, setLanguageMenuOpen] = useState(false);

  return (
    <>
      <img
        src={burger}
        alt="menu"
        style={{ cursor: 'pointer', WebkitTapHighlightColor: 'transparent' }}
        onClick={() => {
          setMenuOpen(true);
        }}
      />
      <Dialog className={'classes.root'} fullScreen open={isMenuOpen} TransitionComponent={Transition}>
        <Box
          sx={{
            backgroundColor: '#121221',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            flexGrow={1}
            sx={{
              transition: 'all 0.3s ease',
              opacity: isLanguageMenuOpen ? 0.5 : 1,
              pointerEvents: isLanguageMenuOpen ? 'none' : '',
            }}
          >
            <Box
              sx={{
                padding: '20px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <img style={{ width: '58px', height: '30px' }} src={logo} alt="logo" />
              <img onClick={() => setMenuOpen(false)} src={close} alt="close" />
            </Box>

            <Box
              sx={{
                marginLeft: '20px',
                marginTop: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
                '& a': {
                  fontFamily: 'Dela400',
                  color: '#FFF',
                  fontSize: '18px',
                  textDecoration: 'none',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    opacity: 0.7,
                  },
                },
              }}
            >
              {LINKS.map((link) => (
                <Link
                  style={{
                    WebkitTapHighlightColor: 'transparent',
                  }}
                  onClick={() => setMenuOpen(false)}
                  key={link.translation}
                  href={link.href}
                >
                  {t(link.translation)}
                </Link>
              ))}
            </Box>

            <Box
              sx={{
                marginLeft: '20px',
                marginTop: '30px',
                display: 'flex',
                gap: '20px',
                '& a': {
                  transition: 'all 0.3s ease',
                },
              }}
            >
              {SOCIAL_LINKS.map((link) => (
                <Link
                  style={{
                    WebkitTapHighlightColor: 'transparent',
                  }}
                  target="_blank"
                  rel="noopener"
                  key={link.alt}
                  href={link.href}
                >
                  <img src={link.src} alt={link.alt} />
                </Link>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              borderTop: '1px solid rgba(205, 207, 246, 0.20)',
              margin: '20px',
              padding: '20px 0',
              display: 'flex',
              gap: '15px',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              sx={{
                background: 'transparent',
                borderRadius: '80px',
                border: '1px solid rgba(205, 207, 246, 0.20)',
                color: '#fff',
                fontFamily: 'Dela400',
                fontSize: '18px',
                textTransform: 'none',
                padding: '20px 30px',
                height: '66px',
                width: '100%',
                '&:hover': {
                  background: 'transparent',
                },
              }}
              onClick={() => window.open(APP_LINKS.LOG_IN, '_blank')}
            >
              {t('navBar.logIn')}
            </Button>
            <Button
              variant="contained"
              sx={{
                background: 'linear-gradient(89deg, #15F1ED 0%, #30FFAA 50.01%, #B1FF31 100%)',
                padding: '20px 30px',
                borderRadius: '80px',
                color: '#121221',
                fontFamily: 'Dela400',
                fontSize: '18px',
                textTransform: 'none',
                height: '66px',
                width: '100%',
              }}
              onClick={() => window.open(APP_LINKS.SIGN_UP, '_blank')}
            >
              {t('navBar.signUp')}
            </Button>
          </Box>

          <Box
            sx={{
              backgroundColor: '#252536',
              position: isLanguageMenuOpen ? 'fixed' : '',
              bottom: 0,
              width: '100%',
            }}
          >
            <Box
              sx={{
                padding: '20px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                flexGrow={1}
                display={'flex'}
                alignItems={'center'}
                gap={'10px'}
                sx={{ cursor: 'pointer', WebkitTapHighlightColor: 'transparent' }}
                onClick={() => setLanguageMenuOpen((prev) => !prev)}
              >
                <img src={selectedLanguage.icon} alt={selectedLanguage.name}></img>
                <Typography
                  sx={{
                    fontFamily: 'Dela400',
                    color: '#fff',
                    fontSize: '14px',
                  }}
                >
                  {t(selectedLanguage.name)}
                </Typography>
              </Box>
              <img
                style={{
                  transform: isLanguageMenuOpen ? 'rotate(180deg)' : '',
                }}
                src={arrow_up}
                alt={'arrow'}
              ></img>
            </Box>
            {isLanguageMenuOpen && (
              <Box
                sx={{
                  borderTop: '1px solid rgba(205, 207, 246, 0.10)',
                  margin: '0 10px',
                }}
              >
                {LANGUAGES.map((language) => (
                  <Box
                    key={language.name}
                    flexGrow={1}
                    display={'flex'}
                    alignItems={'center'}
                    gap={'10px'}
                    sx={{
                      cursor: 'pointer',
                      padding: '15px 20px',
                      margin: '10px -10px',
                      background: selectedLanguage.name === language.name ? 'rgba(205, 207, 246, 0.10)' : '',
                      WebkitTapHighlightColor: 'transparent',
                    }}
                    onClick={() => {
                      i18n.changeLanguage(language.translation);
                      setSelectedLanguage(language);
                      setLanguageMenuOpen(false);
                    }}
                  >
                    <img width={'32px'} height={'32px'} src={language.icon} alt={language.name}></img>
                    <Typography
                      sx={{
                        fontFamily: 'Dela400',
                        color: '#fff',
                        fontSize: '14px',
                      }}
                    >
                      {t(language.name)}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default BurgerMenu;
