import React, { useState } from 'react';
import logo from '../../assets/logo.svg';
import { Box, Button, ClickAwayListener, Link, Tooltip, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LANGUAGES, LINKS, LanguageObj, SOCIAL_LINKS } from './NavBar.constants';
import BurgerMenu from './BurgerMenu';
import { APP_LINKS } from '../../utils/constants';

function NavBar() {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageObj>(LANGUAGES[0]);
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 99,
        width: '100%',
        background: 'linear-gradient(180deg, #121221 0%, rgba(18, 18, 33, 0.00) 100%)',
      }}
    >
      {isMobile ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '20px',
            margin: 'auto',
            justifyContent: 'space-between',
          }}
        >
          <img style={{ width: '58px', height: '30px' }} src={logo} alt="logo" />
          <BurgerMenu selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}></BurgerMenu>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '30px',
            maxWidth: '1980px',
            margin: 'auto',
          }}
        >
          <img src={logo} alt="logo" />

          <Box
            sx={{
              flexGrow: 1,
              marginLeft: '30px',
              display: 'flex',
              flexWrap: 'wrap',
              '& a': {
                fontFamily: 'Dela400',
                color: '#FFF',
                fontSize: '14px',
                textDecoration: 'none',
                transition: 'all 0.3s ease',
                opacity: 0.7,
                margin: '0 20px',
                textWrap: 'nowrap',
                '&:hover': {
                  opacity: 1,
                },
                '@media (max-width: 1200px)': {
                  margin: '0 10px',
                },
              },
            }}
          >
            {LINKS.map((link) => (
              <Link key={link.translation} href={link.href}>
                {t(link.translation)}
              </Link>
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              '& a': {
                transition: 'all 0.3s ease',
                opacity: 0.7,
                margin: '0 10px',
                '&:hover': {
                  opacity: 1,
                },
                '@media (max-width: 1200px)': {
                  margin: '0 5px',
                },
              },
            }}
          >
            {SOCIAL_LINKS.map((link) => (
              <Link target="_blank" rel="noopener" key={link.alt} href={link.href}>
                <img src={link.src} alt={link.alt} />
              </Link>
            ))}
          </Box>
          <Box
            sx={{
              marginLeft: '20px',
              paddingLeft: '30px',
              borderLeft: '1px solid rgba(205, 207, 246, 0.20)',
              display: 'flex',
              alignItems: 'center',
              gap: '30px',
              '@media (max-width: 1200px)': {
                gap: '10px',
              },
            }}
          >
            <Link
              onClick={() => window.open(APP_LINKS.LOG_IN, '_blank')}
              sx={{
                fontFamily: 'Dela400',
                color: '#FFF',
                fontSize: '14px',
                textDecoration: 'none',
                transition: 'all 0.3s ease',
                textWrap: 'nowrap',
                opacity: 0.7,
                '&:hover': {
                  cursor: 'pointer',
                  opacity: 1,
                },
              }}
            >
              {t('navBar.logIn')}
            </Link>

            <Button
              variant="contained"
              sx={{
                background: '#fff',
                borderRadius: '27px',
                color: '#121221',
                fontFamily: 'Dela400',
                fontSize: '14px',
                textTransform: 'none',
                transition: 'all 0.3s ease',
                minWidth: '121px',
                minHeight: '54px',
                textWrap: 'nowrap',
                maxHeight: '54px',
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.70)',
                },
              }}
              onClick={() => window.open(APP_LINKS.SIGN_UP, '_blank')}
            >
              {t('navBar.signUp')}
            </Button>

            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => setOpen(false)}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                componentsProps={{
                  tooltip: {
                    sx: {
                      minWidth: '242px',
                      backgroundColor: '#fff',
                      borderRadius: '15px',
                      padding: '5px',
                      color: '#121221',
                      fontFamily: 'Dela400',
                      fontSize: '14px',
                    },
                  },
                }}
                title={
                  <Box
                    sx={{
                      gap: '5px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {LANGUAGES.map((lang) => (
                      <Box
                        key={lang.name}
                        sx={{
                          padding: '10px',
                          borderRadius: '10px',
                          transition: 'all 0.3s ease',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: 'rgba(205, 207, 246, 0.40)',
                          },
                        }}
                        onClick={() => {
                          i18n.changeLanguage(lang.translation);
                          setSelectedLanguage(lang);
                        }}
                      >
                        <img src={lang.icon} alt="country flag"></img>
                        {t(lang.name)}
                      </Box>
                    ))}
                  </Box>
                }
              >
                <img
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpen(true)}
                  src={selectedLanguage.icon}
                  alt="country flag"
                ></img>
              </Tooltip>
            </ClickAwayListener>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default NavBar;
