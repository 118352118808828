import React from 'react';
import { Box, useMediaQuery } from '@mui/material';

function TermsAndConditions() {
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <Box
      sx={{
        padding: isMobile ? '20px' : '50px',
        '& h1, h2, h3': {
          fontFamily: 'Dela400',
          fontSize: '30px',
          lineHeight: 'normal',
          padding: '10px 0',
          margin: 0,
        },
        '& h2': {
          fontSize: '28px',
          display: 'block',
          borderBottom: '2px solid black',
        },
        '& h3': {
          fontSize: '24px',
        },
        '& p': {
          fontFamily: 'Sans400',
          fontSize: '20px',
          lineHeight: 'normal',
        },
        '& table': {
          fontFamily: 'Sans400',
          fontSize: isMobile ? '16px' : '20px',
          lineHeight: 'normal',
          borderCollapse: 'collapse',
          margin: 'auto',
          '& thead': {
            fontWeight: 600,
            textAlign: 'center',
          },
          '& td': {
            padding: isMobile ? '5px' : '20px',
            border: '1px solid black',
          },
        },
      }}
    >
      <h1>Jet.partners Affiliate Program Terms and Conditions</h1>
      <p>
        The following is an agreement between the relevant Jet.partners company referred to below, ("Jet.partners", "us"
        or "we"), and you ("you"), which contains the terms and conditions that apply to all members of the Jet.partners
        affiliate program ("Agreement").
      </p>
      <p>
        By providing affiliate services and introducing Customers to us, you are contracting with and providing your
        affiliate services to SBS Studio Ltd. It is understood that SBS Studio Ltd. will be procuring and managing your
        services in its capacity as a manager of marketing services for Jet.partners.
      </p>
      <br />
      <br />
      <h2>1. Our Rights and Obligations</h2>
      <h3>1.1. Register your Customers</h3>
      <p>
        Your Customers will register on our website and we will track their transactions. We reserve the right to refuse
        Customers (or to close their accounts) if necessary to comply with any requirements we may periodically
        establish. "Customer" means users who join our customer database (by way of registering at one or more of our
        products/websites including Sports, Casino) after arriving at the applicable site(s) via one of your
        click-through URLs, which will be provided to you when you join. By opening an account with us, they will become
        our Customers and, accordingly, all of our rules, policies, and operating procedures will apply to them. Please
        be aware that the Company does not accept customers who are resident in the USA.
      </p>

      <h3>1.2. Track Customers' Play</h3>
      <p>
        We will track your Customers' bets and plays making available to you a report summarising their activities,
        which you can access from this site.
      </p>

      <h3>1.3. Pay a Referral Commission</h3>
      <p>
        We will pay you referral commission ("Referral Commission") up to 60% of the net profit (% levels will be agreed
        upon negotiation and net profit is defined below) we earn from players directed from your site after they open
        an account with us and wager for real money at either.
      </p>
      <h3>1.4. Modification</h3>
      <p>
        We may modify any of the terms and conditions contained in this Agreement or replace it at any time and in our
        sole discretion by posting a change notice or a new agreement on our site. Modifications may include, for
        example, changes in the scope of available Referral Commissions and Affiliate program rules. If any modification
        is unacceptable to you, your only recourse is to terminate this Agreement. Your continued participation in our
        affiliate program following our posting of a change notice or new agreement on our site will constitute binding
        acceptance of the modification or of the new agreement.
      </p>
      <h3>1.5. Electronic Commerce</h3>
      <p>You acknowledge and agree that you are not a consumer in this Agreement.</p>
      <h3>1.6. Your Application</h3>
      <p>
        To become a member of our affiliate program you will need to accept these terms and conditions by ticking the
        box indicating your acceptance and completing and submitting an online application form. The application form
        will form an integral part of this Agreement. In our sole discretion we will determine whether or not to accept
        your application and our decision is final and not subject to any right of appeal. We will notify you by email
        as to whether or not your application has been successful.
      </p>

      <h2>2. Your Rights and Obligations</h2>
      <h3>2.1. Linking to our Websites</h3>
      <p>
        By agreeing to participate in this Affiliate program, you are agreeing to create and maintain unique links from
        your site to the websites which are owned, operated, branded, associated or referred to by Jet.partners
        (“Jet.partners Websites”). You may link to us with any of our banners, e-mails, articles or with a text link.
        This is the only method by which you may advertise on our behalf.
      </p>
      <h3>2.2. Spamming</h3>
      <p>
        We will terminate this Agreement immediately without recourse for you if there is any form of spamming or if you
        advertise our services in any other way that is not in line with our corporate policies. You shall not make any
        claims or representations, or give any warranties, in connection with us and you shall have no authority to, and
        shall not, bind us to any obligations.
      </p>
      <h3>2.3. Registering of Domain Names</h3>
      <p>
        You shall also refrain from registering (or applying to register) any domain name similar to any domain name
        used by or registered in the name of any companies associated with Jet.partners, or any other name that could be
        understood to designate Jet.partners.
      </p>
      <h3>2.4. Bidding on Brand Terms</h3>
      <p>
        You may not purchase or register keywords, search terms or other identifiers for use in any search engine,
        portal, sponsored advertising service or other search or referral service and which are identical or similar to
        any of Jet.partners or associated companies’ trade marks or otherwise include the word "Jet.partners",
        “variations thereof, or include metatag keywords on the Partner Site which are identical or similar to any of
        the Jet.partners or associated companies’ trade marks. If an affiliate is found to be bidding on brand terms,
        Commission will not be paid for this traffic. For SEO that uses brand keywords – this is allowed but only on a
        low revenue share up to 30%.
      </p>
      <h3>2.5. Agency Appointment</h3>
      <p>
        By this Agreement, we grant you the non-exclusive, non-assignable, right to direct Customers to any of our sites
        in accordance with the terms and conditions of this Agreement. This Agreement does not grant you an exclusive
        right or privilege to assist us in the provision of services arising from your referrals, and we obviously
        intend to contract with and obtain the assistance of others at any time to perform services of the same or
        similar nature as yours. You shall have no claim to Referral Commission or other compensation on business
        secured by or through persons or entities other than you.
      </p>
      <h3>2.6. Approved Layouts</h3>
      <p>
        You will only use our approved advertising creative (banners, html mailers, editorial columns, images and logos)
        and will not alter their appearance nor refer to us in any promotional materials other than those that are
        available from www.jet.partners. The appearance and syntax of the hypertext transfer links are designed and
        designated by us and constitute the only authorised and permitted representation of our sites. In particular,
        please do not create a direct link to the promotional materials on any Jet.partners Website.
      </p>
      <h3>2.7. Good Faith</h3>

      <p>
        You will not knowingly benefit from known or suspected traffic not generated in good faith whether or not it
        actually causes us damage. We reserve the right to retain all amounts otherwise due to you under this Agreement
        if we have reasonable cause to believe that such traffic is generated in good faith.
      </p>
      <p>
        You will not generate traffic to Company Websites by registering as a New Customer whether directly or
        indirectly (for example by using associates, family members or other third parties). Such behaviour shall be
        deemed as fraud.
      </p>
      <p>
        You will also not attempt to benefit from traffic not generated in good faith. If you have any reasonable
        suspicion that any New Customer referred by you is in any way associated to bonus abuse, money laundering,
        fraud, or other abuse of remote gaming websites, you will immediately notify us of this.
      </p>
      <p>
        You hereby recognize that any New Customer found to be a bonus abuser, money launderer or fraudster or who
        assists in any form of affiliate fraud (whether notified by you or later discovered by us) does not count as a
        valid New Customer under the Affiliate Agreement, and thereby no Commission shall be payable in relation to such
        New Customers.
      </p>
      <p>
        You hereby recognize that if 50% of New Customers of your total traffic volume made only a minimum first deposit
        during the current month, then such traffic shall be considered as motivated under the Affiliate Agreement, and
        thereby any Commission in relation to such New Customers can be frozen or not payable upon Company decision.
      </p>
      <p>
        You hereby recognize that you are not allowed to specify the Baseline in creatives, reviews or in any other way
        communicate this to the players. Traffic where an unrealistic distribution of first deposit amounts where all or
        almost all players pass the baseline will be placed under review and with other factors considered Commission
        for this traffic may not be played out. Other factors to determine the quality of traffic include IP’s, email
        and naming patterns, deposit methods used, time stamps, deposit amounts, bet size and patterns, repeat deposits,
        games they play, promos they participate in. Motivated, incentivized or scheme traffic – traffic where players
        are given rewards or prizes to register and make a deposit in our platforms, taught to use specific patterns for
        play, bet specific amounts or to use only specific bonuses or promotions is only possible on revenue share basis
        and with explicit written consent from the affiliate manager. Intent to send such traffic needs to be clearly
        worded by the affiliate and any attempt to hide this will result in Commission not being paid out.
      </p>
      <p>
        You hereby recognize that if 20% of New Customers of your total traffic volume found to be duplicates
        (multi-accounts), bonus abusers, money launderers or fraudsters or assist in any form in affiliate fraud
        (whether notified by you or later discovered by us) do not count as a valid New Customers under the Affiliate
        Agreement, and thereby no Commission shall be payable in relation to whole your traffic.
      </p>
      <p>
        You hereby recognize that if more than 50% of New Customers of your total traffic volume made only the first
        deposit during the current month - the whole traffic volume shall be considered as motivated. In this case, the
        Company has a right not to pay the Commission for such traffic.
      </p>
      <p>
        The Company has a right to ask you by sending the email or notifying via other instant messengers (Skype,
        Telegram, etc.) to suspend your traffic to check the quality of your traffic, as well as to check your traffic
        for fraud. You hereby recognize that by receiving suspension notification from the Company you will stop the
        traffic and all New Customers who registered after this notification will not count as a valid New Customer and
        thereby any Commission in relation to such New Customers can be frozen or no payable upon Company decision.
      </p>
      <p>
        The Company has a right to cancel partner’s current deal and set a new deal unilaterally notifying the Partner
        one (1) bank day in advance. Reasons of cancellation may include:
      </p>

      <p>a) low quality traffic;</p>
      <p>b) low conversion that may lead to a negative balance.</p>
      <p>
        You hereby recognize that after the cancellation of a current deal and setting of the one with unilateral
        notification one (1) bank day beforehand all subsequent traffic that will be brought by the Partner
        (registrations, first deposits and subsequent deposits) will be paid under conditions of a new deal.
      </p>

      <h3>2.8. Responsibility for Your Site</h3>
      <p>
        You will be solely responsible for the development, operation, and maintenance of your site and for all
        materials that appear on your site. For example, you will be solely responsible for ensuring that materials
        posted on your site are not libelous or otherwise illegal. We disclaim all liability for these matters. Further,
        you will indemnify and hold us harmless from all claims, damages, and expenses (including, without limitation,
        legal fees) arising directly or indirectly out of the development, operation, maintenance, and contents of your
        site.
      </p>
      <p>
        The Jet.partners affiliate program is intended for your direct participation. You shall not open affiliate
        accounts on behalf of other participants. Opening an affiliate account for a third party, brokering an affiliate
        account or the transfer of an affiliate account is not accepted. Affiliates wishing to transfer an account to
        another beneficial account owner must request permission to do so by contacting us at partners@jet.partners.
        Approval is solely at our discretion.
      </p>
      <h3>2.9. Affiliation</h3>
      <p> No affiliation can be made between your site and any Jet.partners Website.</p>
      <h3>2.10. License to use Marks</h3>
      <p>
        We hereby grant to you a non-exclusive, non-transferable license, during the term of this Agreement, to use our
        trade name, trade marks, service marks, logos and any other designations, which we may from time to time approve
        ("Marks") solely in connection with the display of the promotional materials on your site. This license cannot
        be sub-licensed, assigned or otherwise transferred by you. Your right to use the Marks is limited to and arises
        only out of this license. You shall not assert the invalidity, unenforceability, or contest the ownership of the
        Marks in any action or proceeding of whatever kind or nature, and shall not take any action that may prejudice
        our rights in the Marks, render the same generic, or otherwise weaken their validity or diminish their
        associated goodwill. You must notify us immediately if you become aware of the misuse of the Marks by any third
        party.
      </p>
      <h3>2.11. Confidential Information</h3>
      <p>
        During the term of this Agreement, you may be entrusted with confidential information relating to our business,
        operations, or underlying technology and/or the Affiliate program (including, for example, Referral Commissions
        earned by you under the Affiliate program). You agree to avoid disclosure or unauthorised use of any such
        confidential information to third persons or outside parties unless you have our prior written consent and that
        you will use the confidential information only for purposes necessary to further the purposes of this Agreement.
        Your obligations with respect to confidential information shall survive the termination of this Agreement.
      </p>
      <h3>2.12. Data Protection</h3>
      <p>
        Where applicable, for the purposes of the General Data Protection Regulation, Jet.partners remains an
        independent Data Controller. For the avoidance of doubt no Data Controller / Data Processor relationship or
        Joint Data Controller relationship is created by this Agreement.
      </p>
      <p>
        Nevertheless, by agreeing to participate in this Affiliate program you shall at all times comply with the
        General Data Protection Regulation and/or any other related or similar applicable legislation. Failure to do so
        will be a cause for the termination of this Agreement with immediate effect.
      </p>
      <p>
        Where, at any time, Jet.partners shares data with you it is at Jet.partners’s sole discretion, may be subject to
        separate terms and conditions, and may be declined or ceased at any time. Should you state that you have a data
        subject’s permission to request their personal data from Jet.partners then Jet.partners may require evidence of
        this in the form of relevant terms and conditions, or specific consent for the sharing.
      </p>

      <h2>3. Referral Commission Calculation</h2>

      <h3>3.1. The Referral Commission</h3>
      <p>You will earn Referral Commission of up to 60% based on the net profit of your referred Customers.</p>
      <p>
        <b>Net profit</b> is defined as:
      </p>

      <p>NGR = GGR - Admin fee - Bonuses</p>
      <p>Admin fee = (17% of GGR) + ((Deposits + Withdrawals) * 12%) where</p>
      <p>&#x2022; 17% is game & sport provider fees</p>
      <p>&#x2022; ((Deposits + Withdrawals) * 12%) is payment gateway fees</p>

      <p>
        We retain the right to change the Referral Commission percentage and method of calculation of Referral
        Commission at our discretion in accordance with clause 1.4.
      </p>

      <h3>3.2. CPA and Hybrid deals</h3>
      <p>
        Negative Revenue Share will be deducted from CPA part of the Commission. This stipulation takes effect unless
        otherwise agreed with the affiliate manager beforehand.
      </p>

      <p>
        Negative NGR is carried over to the next month by default, unless agreed beforehand with the affiliate. The
        company retains the right to enable negative carry over if there are 2 or more months with negative NGR, or if
        the affiliate is targeting bonus users, pages or focusing on bond promotion. In cases when negative NGR is
        caused by users who are bonus abusers, the company and affiliate upon agreement can remove these users from the
        statistics.
      </p>

      <p>
        Duplicate accounts and self-excluded players will be deducted from CPA part of the Commission. This stipulation
        takes effect unless alternative was discussed with affiliate manager beforehand.
      </p>

      <p>
        In cases certain CAP was negotiated with a partner Commission will be paid only for the negotiated number of
        FTDs.
      </p>

      <h2>4. Referral Commission Payouts</h2>
      <h3>4.1. Referral Fee Payout Requests</h3>
      <p>
        You are entitled to one payout per month, providing you have fulfilled the above Customer requirement at the end
        of the preceding month.
      </p>
      <p>
        In order to receive first payout, affiliate must bring a one-time cap of 5 FTD to any of our brands over any
        period of time.
      </p>
      <p>
        In order to receive a timely payout for the previous month, you need to send invoice before the 10th of the
        current month. Failure to do so may result in delays processing your payment.
      </p>
      <p>The affiliate commissions will be credited until 10th day of each month.</p>
      <p>
        Payout requests for any calendar month’s Referral Commissions may be made anytime from the start of the second
        day (Cyprus time) of the following calendar month.
      </p>
      <p>
        The minimum payout request for earned Referral Commission per month depends upon the currency denomination of
        your affiliate account as shown below. There is no maximum limit.
      </p>

      <table>
        <thead>
          <tr>
            <td>Currency</td>
            <td>Minimum Payout Request for affiliate account</td>
            <td>Minimum Payout Request for invoice</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Euros</td>
            <td>500 EUR</td>
            <td>500 EUR</td>
          </tr>
          <tr>
            <td>Bitcoin</td>
            <td>500 EUR Equivalent in crypto</td>
            <td>500 EUR Equivalent in crypto</td>
          </tr>
          <tr>
            <td>United States Dollars</td>
            <td>500 USD</td>
            <td>500 USD</td>
          </tr>
        </tbody>
      </table>

      <p>Referral Commission payments may be requested:</p>
      <p>
        (i) to a nominated Jet.partners account - recommended, the fastest and easiest way to withdraw money via your
        choice of any of the payment methods supported by Jet.partners
      </p>
      <p>(ii) bank wire, or Epayments via invoice</p>
      <p>
        Please be aware that although Jet.partners will not deduct any charges for arranging a bank wire, your own bank
        may choose to levy a charge on such transactions.{' '}
      </p>

      <p>
        An active referred Customer is defined as a visitor to your site(s) who has clicked through to a Jet.partners
        Website via your click-through link, has registered as a customer, deposited funds to their customer account and
        conducted betting or playing activity in one or more Jet.partners products.
      </p>

      <h3>4.2. Referral Commission Payment Currency</h3>
      <p>
        All Referral Commission Payments will be due and paid in the currency which was selected when your Affiliate
        Account was first set up, except for payment requests. Where currency conversion is required, all amounts are
        converted at the midpoint applying at the time of payment, as chosen by Jet.partners.
      </p>
      <p>
        Referral Commissions arising from Customer Accounts that are held in currencies other than the affiliate’s home
        currency will be converted at the mid-point which applied at the time the Referral Commission was earned.
      </p>

      <h2>5. Term and Termination, Consequences and Unsuitable Sites</h2>
      <h3>5.1. Term and Termination</h3>
      <p>
        The term of the Affiliate Agreement will begin when you are approved as an Affiliate and will be continuous
        unless and until either Party notifies the other in writing that it wishes to terminate the Agreement. The
        Agreement may be terminated for the following reasons: fraudulent traffic (bots, multiaccounts,
        selfaffiliation), breach of marketing rules (brand bidding, spamming and others specified this Agreement).
        Depending on the severity of the rules breached, the Agreement may be terminated immediately and indefinitely.
        For purposes of notification of termination, delivery via e-mail is considered a written and immediate form of
        notification
      </p>
      <h3>5.2. Consequence</h3>
      <p>
        Upon termination you must remove all of our banners/icons from your site and disable all links from your site to
        all Jet.partners Websites. All rights and licenses given to you in this Agreement shall immediately terminate.
        You will return to us any confidential information, and all copies of it in your possession, custody and control
        and will cease all uses of our Marks.
      </p>
      <h3>5.3. Unsuitable Sites</h3>
      <p>
        We may terminate this Agreement if we determine (in our sole discretion) that your site is unsuitable.
        Unsuitable sites include, but are not limited to, those that: are aimed at children, display pornography or
        other illegal sexual acts, promote violence, promote discrimination based on race, sex, religion, nationality,
        disability, sexual orientation, or age, promote illegal activities or violate intellectual property rights or
        breach any relevant advertising regulations or codes of practice.
      </p>
      <h3>5.4. Duplicate Accounts and Self Referrals</h3>
      <p>
        You shall not open more than one affiliate account without our prior written consent nor will you earn
        Commission on your own or related person's Jet.partners sports and casino account. The program is intended for
        professional website publishers.
      </p>

      <h2>6. Continued Promotion</h2>
      <p>
        You shall incorporate and prominently and continually display the most up-to-date links provided by us on all
        pages of your website in a manner and location agreed by us and you shall not alter the form, location or
        operation of the links without our prior written consent. You are eligible for Referral Commissions based upon
        your continued promotion of Jet.partners Website. We reserve the right to reduce Referral Commission percentages
        (no lower than 25%) if you reduce your efforts to recruit new Customers. The same rule applies for
        subaffiliation – if the affiliate makes no continued effort to onboard new partners, their subaffiliate
        Commission is reduced (not lower than 3%).
      </p>

      <h2>7. Relationship of Parties</h2>
      <p>
        We and you are independent contractors and nothing in this Agreement will create any partnership, joint venture,
        agency, franchise, sales representative, or employment relationship between us. You will have no authority to
        make or accept any offers or representations on our behalf. You will not make any statement, whether on your
        site or otherwise, that would contradict anything in this Agreement.
      </p>

      <h2>8. Indemnity</h2>
      <p>
        You shall defend, indemnify, and hold us, our directors, employees and representatives harmless from and against
        any and all liabilities, losses, damages and costs, including legal fees, resulting from, arising out of, or in
        any way connected with (a) any breach by you of any warranty, representation or term contained in this
        Agreement, (b) the performance of your duties and obligations under this Agreement, (c) your negligence or (d)
        any injury caused directly or indirectly by your negligent or intentional acts or omissions, or the unauthorised
        use of our banners and links or this Affiliate program.
      </p>
      <h2>9. Disclaimers</h2>
      <p>
        We make no express or implied warranties or representations with respect to the Affiliate program, about
        ourselves or the Referral Commission payment arrangements (including, without limitation, functionality,
        warranties of fitness, merchantability, legality or non-infringement), and do not express nor imply any
        warranties arising out of a course of performance, dealing, or trade usage. In addition, we make no
        representation that the operation of our sites will be uninterrupted or error-free and will not be liable for
        the consequences if there are any. In the event of a discrepancy between the reports offered in the
        www.jet.partners system and the Jet.partners database, the database shall be deemed accurate.
      </p>

      <h2>10. Limitation of Liability</h2>
      <p>
        We will not be liable for indirect, special, or consequential damages (or any loss of revenue, profits, or data)
        arising in connection with this Agreement or the affiliate program, even if we have been advised of the
        possibility of such damages. Further, our aggregate liability arising with respect to this Agreement and the
        affiliate program will not exceed the total Referral Commissions paid or payable to you under this Agreement.
        Nothing in this Agreement shall be construed to provide any rights, remedies or benefits to any person or entity
        not a party to this Agreement. Our obligations under this Agreement do not constitute personal obligations of
        our directors, employees or shareholders. Any liability arising under this Agreement shall be satisfied solely
        from the Referral Commission generated and is limited to direct damages.
      </p>

      <h2>11. Independent Investigation</h2>
      <p>
        You acknowledge that you have read this Agreement and agree to all its Terms and Conditions. You understand that
        we may at any time (directly or indirectly) solicit customer referrals on terms that may differ from those
        contained in this Agreement or operate or contract with websites that are similar to or compete with your
        website. You have independently evaluated the desirability of participating in this Affiliate program and are
        not relying on any representation, guarantee, or statement other than as set out in this Agreement.
      </p>

      <h2>12. Miscellaneous</h2>
      <h3>12.1. Governing Law</h3>
      <p>
        The laws of BVI will govern this Agreement, without reference to rules governing choice of law. Any action
        relating to this Agreement must be brought in BVI and you irrevocably consent to the jurisdiction of its courts.
      </p>
      <h3>12.2. Assignability</h3>
      <p>
        You may not assign this Agreement, by operation of law or otherwise, without our prior written consent. Subject
        to that restriction, this Agreement will be binding on, inure to the benefit of, and be enforceable against you
        and us and our respective successors and assigns.
      </p>
      <h3>12.3. Non-Waiver</h3>
      <p>
        Our failure to enforce your strict performance of any provision of this Agreement will not constitute a waiver
        of our right to subsequently enforce such provision or any other provision of this Agreement. No modifications,
        additions, deletions or interlineations of this Agreement are permitted or will be recognised by us. None of our
        employees or agents has any authority to make or to agree to any alterations or modifications to this Agreement
        or its terms.
      </p>
      <h3>12.4. Remedies</h3>
      <p>
        Our rights and remedies hereunder shall not be mutually exclusive, that is to say that the exercise of one or
        more of the provisions of this Agreement shall not preclude the exercise of any other provision. You
        acknowledge, confirm, and agree that damages may be inadequate for a breach or a threatened breach of this
        Agreement and, in the event of a breach or threatened breach of any provision of this Agreement, we may seek
        enforcement or compliance by specific performance, injunction, or other equitable remedy. Nothing contained in
        this Agreement shall limit or affect any of our rights at law, or otherwise, for a breach or threatened breach
        of any provision of this Agreement, its being the intention of this provision to make clear that our rights
        shall be enforceable in equity as well as at law or otherwise.
      </p>
      <h3>12.5. Severability/Waiver</h3>
      <p>
        Whenever possible, each provision of this Agreement shall be interpreted in such a manner as to be effective and
        valid under applicable law but, if any provision of this Agreement is held to be invalid, illegal or
        unenforceable in any respect, such provision will be ineffective only to the extent of such invalidity, or
        unenforceability, without invalidating the remainder of this Agreement or any provision hereof. No waiver will
        be implied from conduct or failure to enforce any rights and must be in writing to be effective.
      </p>
    </Box>
  );
}

export default TermsAndConditions;
