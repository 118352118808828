import React, { useEffect, useState } from 'react';
import { keyframes } from '@emotion/react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { REVIEWS } from './Reviews.constants';

const ReviewCard = ({
  review,
}: {
  review: {
    name: string;
    role: string;
    link: string;
    reviewText: string;
    image: Promise<typeof import('*.webp')>;
  };
}) => {
  const [image, setImage] = useState('');
  const isMobile = useMediaQuery('(max-width:1000px)');
  const { t } = useTranslation();

  useEffect(() => {
    const loadImage = async () => {
      const img = await review.image;
      setImage(img.default);
    };

    loadImage();
  }, [review]);

  return (
    <Box
      key={review.reviewText}
      sx={{
        background: '#202030',
        minWidth: isMobile ? '305px' : '508px',
        borderRadius: '20px',
        padding: isMobile ? '15px 20px 20px' : '30px 40px 40px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <img
        style={{
          width: isMobile ? '160px' : '200px',
          height: isMobile ? '64px' : '80px',
          marginBottom: '10px',
        }}
        src={image}
        alt="logo"
      ></img>
      <Typography
        sx={{
          fontFamily: 'Sans400',
          fontSize: isMobile ? '18px' : '24px',
          color: 'rgba(205, 207, 246, 0.70)',
          lineHeight: isMobile ? '28px' : '38px',
          opacity: 0.7,
          flexGrow: 1,
        }}
      >
        {t(review.reviewText)}
      </Typography>
      <Box
        sx={{
          borderTop: '2px solid rgba(205, 207, 246, 0.10)',
          margin: isMobile ? '20px 0' : '40px 0',
        }}
      ></Box>

      <Typography
        sx={{
          fontFamily: 'Dela400',
          fontSize: isMobile ? '16px' : '20px',
          color: 'white',
          lineHeight: 'normal',
          marginBottom: '5px',
        }}
      >
        {review.link}
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Sans400',
          fontSize: isMobile ? '14px' : '20px',
          color: 'rgba(205, 207, 246, 0.70)',
          lineHeight: 'normal',
          opacity: 0.7,
        }}
      >
        {t(review.name)}, {t(review.role)}
      </Typography>
    </Box>
  );
};

function Reviews() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:1000px)');
  const size = (588 + 40) * REVIEWS.length;

  const keyframesLeft = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-${size}px);
  }
  `;

  const keyframesRight = keyframes`
  from {
    transform: translateX(-${size}px);
  }
  to {
    transform: translateX(0px);
  }
  `;

  function shuffle(array: typeof REVIEWS) {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex > 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  return (
    <>
      <Box
        id="testimonials"
        sx={{
          scrollMarginTop: isMobile ? '70px' : '115px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: isMobile ? '0 20px' : '0 30px',
        }}
      >
        <Box
          sx={{
            maxWidth: '1216px',
            width: '100%',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontFamily: 'Dela400',
              fontSize: isMobile ? '28px' : '40px',
              color: 'white',
              maxWidth: isMobile ? undefined : '804px',
              lineHeight: 'normal',
              textAlign: isMobile? 'left':'center',
              margin: 'auto',
              marginBottom: isMobile ? '40px' : '60px',
            }}
          >
            {t('reviews.title')}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: isMobile ? '10px' : '40px',
          overflow: 'hidden',
          paddingBottom: isMobile ? '60px' : '80px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: isMobile ? '10px' : '40px',
            animation: `${keyframesLeft} 50s infinite linear`,
          }}
        >
          {[1, 2].map(() =>
            shuffle(REVIEWS).map((review, index) => <ReviewCard review={review} key={review.name + index}></ReviewCard>)
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: isMobile ? '10px' : '40px',
            animation: `${keyframesRight} 50s infinite linear`,
          }}
        >
          {[1, 2].map(() =>
            shuffle(REVIEWS).map((review, index) => <ReviewCard review={review} key={review.name + index}></ReviewCard>)
          )}
        </Box>
      </Box>
    </>
  );
}

export default Reviews;
