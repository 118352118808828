import en from '../../assets/flags/en.svg';
import de from '../../assets/flags/de.svg';
import pt from '../../assets/flags/pt.svg';
import es from '../../assets/flags/es.svg';
// import telegram from '../../assets/social/telegram.svg';
// import instagram from '../../assets/social/instagram.svg';
// import linkedin from '../../assets/social/linkedin.svg';
// import { APP_LINKS } from '../../utils/constants';

export type LanguageObj = {
  name: string;
  icon: string;
  translation: string;
};

export const LANGUAGES: LanguageObj[] = [
  {
    name: 'navBar.languages.english',
    icon: en,
    translation: 'en',
  },
  {
    name: 'navBar.languages.german',
    icon: de,
    translation: 'de',
  },
  {
    name: 'navBar.languages.portuguese',
    icon: pt,
    translation: 'pt',
  },
  {
    name: 'navBar.languages.spanish',
    icon: es,
    translation: 'es',
  },
];

export const LINKS = [
  {
    translation: 'navBar.features',
    href: '#features',
  },
  {
    translation: 'navBar.testimonials',
    href: '#testimonials',
  },
  {
    translation: 'navBar.about',
    href: '#about',
  },
  {
    translation: 'navBar.plans',
    href: '#plans',
  },
  {
    translation: 'navBar.faq',
    href: '#faq',
  },
];

export const SOCIAL_LINKS: { src: string; href: string; alt: string }[] = [
  // {
  //   src: telegram,
  //   href: APP_LINKS.TELEGRAM,
  //   alt: 'telegram',
  // },
  // {
  //   src: instagram,
  //   href: APP_LINKS.INSTAGRAM,
  //   alt: 'instagram',
  // },
  // {
  //   src: linkedin,
  //   href: APP_LINKS.LINKEDIN,
  //   alt: 'linkedin',
  // },
];
