import React, { useState } from 'react';

import { Box, Button, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import background from './assets/background.webp';

function GetInTouch({
  setLoadingError,
  setModalOpen,
}: {
  setLoadingError: (state: boolean) => void;
  setModalOpen: (state: boolean) => void;
}) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:1000px)');
  const DEFAULT_VALUES = { name: '', email: '', message: '' };
  const [values, setValues] = useState(DEFAULT_VALUES);
  const [errors, setErrors] = useState({ name: false, email: false, message: false });
  const [isLoading, setLoading] = useState(false);

  const handleSend = () => {
    const isValidEmail = String(values.email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    const errors = {
      name: values.name === '',
      email: isValidEmail === null ? true : false,
      message: values.message === '',
    };
    setErrors(errors);

    if (!Object.values(errors).some((val) => val === true)) {
      setLoading(true);
      fetch('https://jet.partners/remote-mailer', {
        method: 'POST',
        body: JSON.stringify({
          from: values.email,
          subject: values.name,
          body: values.message,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
        .then((response) => {
          console.log('response', response);
        })
        .catch((err: any) => {
          console.log('fetch remote-mailer err', err);
          setLoadingError(true);
        })
        .finally(() => {
          setModalOpen(true);
          setLoading(false);
          setValues(DEFAULT_VALUES);
        });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: isMobile ? '0 20px' : '0 30px',
        backgroundImage: isMobile ? undefined : `url(${background})`,
        backgroundSize: '1920px 1086px',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: '598px',
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Dela400',
            fontSize: isMobile ? '28px' : '40px',
            color: 'white',
            lineHeight: 'normal',
            marginTop: isMobile ? '60px' : '160px',
            marginBottom: '15px',
          }}
        >
          {t('getInTouch.title')}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Sans400',
            fontSize: isMobile ? '18px' : '24px',
            color: 'rgba(205, 207, 246, 0.70)',
            lineHeight: isMobile ? '28px' : '38px',
          }}
        >
          {t('getInTouch.subTitle')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: isMobile ? '20px' : '30px',
            padding: isMobile ? '30px 0 ' : '40px 0',
            '& .title': {
              fontFamily: 'Sans400',
              fontSize: isMobile ? '18px' : '20px',
              color: 'white',
              lineHeight: 'normal',
            },
            '& input, textarea': {
              resize: 'none',
              cursor: 'pointer',
              fontFamily: 'Sans400',
              fontSize: isMobile ? '18px' : '24px',
              color: 'white',
              borderRadius: '8px',
              border: 'none',
              background: 'rgba(205, 207, 246, 0.10)',
              padding: isMobile ? '18px 20px' : '20px 30px',
              transition: 'all 0.3s ease',
              '&::placeholder': {
                color: 'rgba(205, 207, 246, 0.70)',
                transition: 'all 0.3s ease',
              },

              '&:hover': {
                background: 'rgba(205, 207, 246, 0.15)',
                transition: 'all 0.3s ease',

                '&::placeholder': {
                  color: '#CDCFF6',
                  opacity: 0.3,
                },
              },
              '&:focus': {
                background: 'rgba(205, 207, 246, 0.15)',
                outline: 'none',
              },
            },
            '& .error': {
              color: 'white',
              display: 'block',
              borderRadius: '5px',
              backgroundColor: '#E61E42',
              padding: '5px 10px',

              fontFamily: 'Sans600',
              fontSize: isMobile ? '10px' : '12px',
              lineHeight: 'normal',
              fontWeight: 600,
              position: 'relative',
              '&::after': {
                position: 'absolute',
                content: '""',
                display: 'block',
                top: '100%',
                left: 'calc(50% - 12px)',
                width: 0,
                height: 0,

                borderLeft: '12px solid transparent',
                borderRight: '12px solid transparent',

                borderTop: '6px solid #E61E42',
              },
            },
          }}
        >
          <Box display={'flex'} gap={isMobile ? '10px' : '15px'} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography className="title">{t('getInTouch.fullName.title')}</Typography>
              <Typography
                sx={{
                  visibility: errors.name ? 'visible' : 'hidden',
                }}
                className="error"
              >
                {t('getInTouch.fullName.error')}
              </Typography>
            </Box>
            <input
              value={values.name}
              onChange={(e) => {
                setValues((prev) => ({ ...prev, name: e.target.value }));
                setErrors((prev) => ({ ...prev, name: false }));
              }}
              type="text"
              placeholder={t('getInTouch.fullName.placeholder')}
            ></input>
          </Box>
          <Box display={'flex'} gap={isMobile ? '10px' : '15px'} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography className="title">{t('getInTouch.email.title')}</Typography>
              <Typography
                sx={{
                  visibility: errors.email ? 'visible' : 'hidden',
                }}
                className="error"
              >
                {t('getInTouch.email.error')}
              </Typography>
            </Box>
            <input
              value={values.email}
              onChange={(e) => {
                setValues((prev) => ({ ...prev, email: e.target.value }));
                setErrors((prev) => ({ ...prev, email: false }));
              }}
              type="email"
              placeholder={t('getInTouch.email.placeholder')}
            ></input>
          </Box>
          <Box display={'flex'} gap={isMobile ? '10px' : '15px'} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography className="title">{t('getInTouch.message.title')}</Typography>
              <Typography
                sx={{
                  visibility: errors.message ? 'visible' : 'hidden',
                }}
                className="error"
              >
                {t('getInTouch.message.error')}
              </Typography>
            </Box>
            <textarea
              value={values.message}
              onChange={(e) => {
                setValues((prev) => ({ ...prev, message: e.target.value }));
                setErrors((prev) => ({ ...prev, message: false }));
              }}
              placeholder={t('getInTouch.message.placeholder')}
              rows={3}
            ></textarea>
          </Box>
        </Box>
        <Button
          sx={{
            padding: isMobile ? '20px 30px' : '20px 40px',
            borderRadius: '80px',
            background: 'linear-gradient(89deg, #15F1ED 0%, #30FFAA 50.01%, #B1FF31 100%)',
            color: '#121221',
            fontFamily: 'Dela400',
            fontSize: isMobile ? '18px' : '20px',
            lineHeight: 'normal',
            transition: 'all 0.3s ease',
            marginBottom: isMobile ? '80px' : '160px',
            width: '100%',
            '&:hover': {
              background: 'linear-gradient(89deg, #15F1ED 0%, #30FFAA 50.01%, #B1FF31 100%)',
              opacity: 0.7,
            },
            '& .MuiButton-startIcon': {
              marginRight: isMobile ? '10px' : '20px',
            },
          }}
          onClick={handleSend}
        >
          {isLoading ? (
            <CircularProgress
              size={26}
              sx={{
                color: '#121221',
                // maxHeight: '16px'
              }}
            ></CircularProgress>
          ) : (
            t('getInTouch.button')
          )}
        </Button>
      </Box>
    </Box>
  );
}

export default GetInTouch;
