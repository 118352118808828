import React from 'react';

import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { APP_LINKS } from '../../utils/constants';

import background from './assets/background.webp';
import background_mob from './assets/background_mob.webp';
import button_icon from './assets/button_icon.svg';
import en from '../../assets/flags/en.svg';
import de from '../../assets/flags/de.svg';
import pt from '../../assets/flags/pt.svg';
import ru from '../../assets/flags/ru.svg';

function About() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <Box
      id="about"
      sx={{
        scrollMarginTop: isMobile ? '70px' : '115px',
      }}
    >
      {isMobile && (
        <img
          style={{
            width: '100%',
          }}
          src={background_mob}
          alt="background"
        ></img>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundImage: isMobile ? undefined : `url(${background})`,
          backgroundSize: '1920px 1003px',
          backgroundPosition: 'right top',
          '@media (max-width: 1900px)': {
            backgroundPosition: 'center top',
          },
          '@media (max-width: 1300px)': {
            backgroundPosition: '-300px top',
          },
          backgroundRepeat: 'no-repeat',
          padding: isMobile ? '0 20px' : '0 30px',
          paddingBottom: isMobile ? undefined : '120px',
        }}
      >
        <Box
          sx={{
            maxWidth: '1216px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              maxWidth: isMobile ? '100%' : '495px',
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontFamily: 'Dela400',
                fontSize: isMobile ? '28px' : '40px',
                color: 'white',
                lineHeight: 'normal',
                marginTop: isMobile ? '40px' : '120px',
              }}
            >
              {t('about.title')}
            </Typography>

            <Typography
              variant="h3"
              sx={{
                fontFamily: 'Sans400',
                fontSize: isMobile ? '18px' : '24px',
                color: 'rgba(205, 207, 246, 0.70)',
                lineHeight: isMobile ? '28px' : '38px',
                maxWidth: '804px',
                marginTop: '15px',
              }}
            >
              {t('about.subTitle')}
            </Typography>
            <Box
              sx={{
                '& .title': {
                  fontFamily: 'Sans400',
                  fontSize: isMobile ? '18px' : '20px',
                  color: 'rgba(205, 207, 246, 0.70)',
                  lineHeight: 'normal',
                  marginTop: isMobile ? '30px' : '50px',
                  marginBottom: '5px',
                },
                '& .subTitle': {
                  fontFamily: 'Dela400',
                  fontSize: isMobile ? '18px' : '20px',
                  color: 'white',
                  lineHeight: 'normal',
                },
              }}
            >
              <Typography className="title">{t('about.license.title')}:</Typography>
              <Typography className="subTitle">{t('about.license.text')}</Typography>

              <Typography className="title">{t('about.product.title')}:</Typography>
              <Typography className="subTitle">{t('about.product.text')}</Typography>

              <Typography className="title">{t('about.languages.title')}:</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '15px 20px',
                  marginTop: '15px',
                  '& .wrapper': {
                    display: 'flex',
                    gap: '10px',
                  },
                }}
              >
                <Box className="wrapper">
                  <img src={en} alt="language"></img>
                  <Typography className="subTitle">{t('about.languages.text.english')}</Typography>
                </Box>
                <Box className="wrapper">
                  <img src={de} alt="language"></img>
                  <Typography className="subTitle">{t('about.languages.text.german')}</Typography>
                </Box>
                <Box className="wrapper">
                  <img src={ru} alt="language"></img>
                  <Typography className="subTitle">{t('about.languages.text.russian')}</Typography>
                </Box>
                <Box className="wrapper">
                  <img src={pt} alt="language"></img>
                  <Typography className="subTitle">{t('about.languages.text.brazilianPortuguese')}</Typography>
                </Box>
              </Box>
            </Box>
            <Button
              startIcon={
                <img
                  style={{
                    width: isMobile ? '24px' : '40px',
                  }}
                  src={button_icon}
                  alt="button"
                ></img>
              }
              sx={{
                padding: isMobile ? '20px 30px' : '30px 50px',
                borderRadius: '80px',
                background: 'linear-gradient(89deg, #15F1ED 0%, #30FFAA 50.01%, #B1FF31 100%)',
                color: '#121221',
                fontFamily: 'Dela400',
                fontSize: isMobile ? '18px' : '20px',
                transition: 'all 0.3s ease',
                marginTop: isMobile ? '40px' : '60px',
                marginBottom: isMobile ? '80px' : '60px',
                width: isMobile ? '100%' : undefined,
                '&:hover': {
                  background: 'linear-gradient(89deg, #15F1ED 0%, #30FFAA 50.01%, #B1FF31 100%)',
                  opacity: 0.7,
                },
                '& .MuiButton-startIcon': {
                  marginRight: isMobile ? '10px' : '20px',
                },
              }}
              onClick={() => {
                window.open(APP_LINKS.BRAND_PROMO_PACK, '_blank');
              }}
            >
              {t('about.button')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default About;
