import React from 'react';
import background_mob from './assets/background_mob_2.webp';
import background from './assets/background.jpg';
import arrow from './assets/arrow_down.svg';
import desktopStep1 from './assets/desktopSteps/1.webp';
import desktopStep2 from './assets/desktopSteps/2.webp';
import desktopStep3 from './assets/desktopSteps/3.webp';
import mob_background from './assets/desktopSteps/mob.webp';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { APP_LINKS } from '../../utils/constants';

function Header() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundImage: isMobile ? `url(${background_mob})` : `url(${background})`,
          backgroundSize: isMobile ? 'contain' : '3600px 931px',
          backgroundPosition: isMobile ? 'right top' : 'top',
          backgroundRepeat: 'no-repeat',
          padding: isMobile ? '0 20px' : '0 30px',
          paddingBottom: isMobile ? undefined : '120px',
        }}
      >
        <Box
          sx={{
            maxWidth: '1216px',
            width: '100%',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontFamily: 'Dela400',
              fontSize: isMobile ? '28px' : '72px',
              background: 'linear-gradient(89deg, #15F1ED 0%, #30FFAA 50.01%, #B1FF31 100%)',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              WebkitBackgroundClip: 'text',
              maxWidth: '907px',
              lineHeight: 'normal',
              marginTop: isMobile ? '80px' : '194px',
              marginRight: '110px',
            }}
          >
            {t('header.title')}
          </Typography>

          <Typography
            variant="h3"
            sx={{
              fontFamily: 'Sans400',
              fontSize: isMobile ? '18px' : '24px',
              color: 'rgba(205, 207, 246, 0.70)',
              lineHeight: isMobile ? '28px' : '38px',
              maxWidth: '804px',
              marginTop: isMobile ? '20px' : '40px',
              marginBottom: isMobile ? '30px' : '50px',
            }}
          >
            {t('header.subTitle')}
          </Typography>
          <Box display={'flex'} gap={'40px'}>
            <Button
              sx={{
                padding: isMobile ? '20px 30px' : '30px 50px',
                borderRadius: '80px',
                background: 'linear-gradient(89deg, #15F1ED 0%, #30FFAA 50.01%, #B1FF31 100%)',
                color: '#121221',
                fontFamily: 'Dela400',
                fontSize: '20px',
                transition: 'all 0.3s ease',
                width: isMobile ? '100%' : 'inherit',
                '&:hover': {
                  background: 'linear-gradient(89deg, #15F1ED 0%, #30FFAA 50.01%, #B1FF31 100%)',
                  opacity: 0.7,
                },
              }}
              onClick={() => window.open(APP_LINKS.SIGN_UP, '_blank')}
            >
              {t('header.getStarted')}
            </Button>

            {!isMobile && (
              <Box
                onClick={() => {
                  window.location.href = '#features';
                }}
                sx={{
                  color: '#FFF',
                  fontFamily: 'Dela400',
                  fontSize: '20px',
                  textTransform: 'uppercase',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                  cursor: 'pointer',
                  '&:hover': {
                    '& div': {
                      transition: 'all 0.3s ease',

                      border: '2px solid rgba(205, 207, 246, 0.40)',
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    width: '89px',
                    height: '89px',
                    border: '2px solid rgba(205, 207, 246, 0.20)',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img src={arrow} alt="learn more"></img>
                </Box>
                {t('header.learnMore')}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          padding: isMobile ? '0 0 0 20px' : '0 30px',
        }}
      >
        <Box
          id="features"
          sx={{
            scrollMarginTop: isMobile ? '0px' : '115px',
            display: 'flex',
            margin: 'auto',
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? '60px' : '40px',
            maxWidth: '1216px',
            width: '100%',
            paddingTop: isMobile ? '80px' : undefined,
            paddingBottom: isMobile ? '60px' : '120px',
            backgroundImage: isMobile ? `url(${mob_background})` : '',
            backgroundPosition: 'right',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            '& div': {
              width: isMobile ? '100%' : '30%',
              '& p:first-of-type': {
                fontFamily: 'Dela400',
                color: '#FFF',
                fontSize: isMobile ? '28px' : '40px',
                marginBottom: '8px',
              },
              '& p:last-of-type': {
                fontFamily: 'Sans400',
                fontSize: isMobile ? '18px' : '24px',
                lineHeight: isMobile ? '28px' : '38px',
                color: 'rgba(205, 207, 246, 0.70)',
                marginRight: isMobile ? '150px' : undefined,
              },
            },
            '& img': {
              width: '100%',
              display: isMobile ? 'none' : 'inherit',
            },
          }}
        >
          <Box>
            <img src={desktopStep1} alt="step"></img>
            <Typography>{t('header.step1.title')}</Typography>
            <Typography>{t('header.step1.subTitle')}</Typography>
          </Box>
          <Box>
            <img src={desktopStep2} alt="step"></img>
            <Typography>{t('header.step2.title')}</Typography>
            <Typography>{t('header.step2.subTitle')}</Typography>
          </Box>
          <Box>
            <img src={desktopStep3} alt="step"></img>
            <Typography>{t('header.step3.title')}</Typography>
            <Typography>{t('header.step3.subTitle')}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Header;
