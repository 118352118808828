import React from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import chart from './assets/chart.svg';
import chart_mob from './assets/chart_mob.svg';

function Plans() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <Box
      id="plans"
      sx={{
        scrollMarginTop: isMobile ? '70px' : '115px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: isMobile ? 0 : '0 30px',
        paddingBottom: isMobile ? '80px' : '120px',
      }}
    >
      <Box
        sx={{
          maxWidth: '1216px',
          width: '100%',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontFamily: 'Dela400',
            fontSize: isMobile ? '28px' : '40px',
            color: 'white',
            lineHeight: 'normal',
            marginTop: isMobile ? 0 : '3px',
            maxWidth: isMobile ? '80%' : '480px',
            position: isMobile ? undefined : 'absolute',
            margin: isMobile ? '0 20px' : undefined,
          }}
        >
          {t('plans.title')}
        </Typography>
        <img
          style={{
            width: '100%',
          }}
          src={isMobile ? chart_mob : chart}
          alt="chart"
        ></img>
      </Box>
    </Box>
  );
}

export default Plans;
