import React, { useEffect } from 'react';

import { Box, Button, Fade, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ok from './assets/ok.svg';

function ThankYouModal({
  isModalOpen,
  isLoadingError,
  setModalOpen,
  setLoadingError,
}: {
  isModalOpen: boolean;
  isLoadingError: boolean;
  setModalOpen: (state: boolean) => void;
  setLoadingError: (state: boolean) => void;
}) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:1000px)');

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isModalOpen]);

  return (
    <Fade in={isModalOpen}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 999,
          background: 'rgba(18, 18, 33, 0.95)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: '498px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <img
            style={{
              width: isMobile ? '160px' : '220px',
            }}
            src={ok}
            alt="ok"
          ></img>
          <Typography
            sx={{
              fontFamily: 'Dela400',
              fontSize: isMobile ? '28px' : '40px',
              color: 'white',
              lineHeight: 'normal',
              textAlign: 'center',
              marginTop: isMobile ? '10px' : '20px',
            }}
          >
            {t('getInTouch.modal.title')}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Sans400',
              fontSize: isMobile ? '18px' : '24px',
              color: 'rgba(205, 207, 246, 0.70)',
              lineHeight: isMobile ? '28px' : '38px',
              textAlign: 'center',
              maxWidth: '392px',
              marginTop: isMobile ? '10px' : '20px',
              marginBottom: isMobile ? '20px' : '30px',
            }}
          >
            {t('getInTouch.modal.subTitle')}
          </Typography>
          <Button
            sx={{
              padding: isMobile ? '20px 30px' : '20px 40px',
              borderRadius: '80px',
              background: 'white',
              color: '#121221',
              fontFamily: 'Dela400',
              fontSize: isMobile ? '18px' : '20px',
              lineHeight: 'normal',
              transition: 'all 0.3s ease',
              width: '100%',
              maxWidth: '392px',
              '&:hover': {
                background: 'white',
                opacity: 0.7,
              },
            }}
            onClick={() => {
              setLoadingError(false);
              setModalOpen(false);
            }}
          >
            {t('getInTouch.modal.button')}
          </Button>
          {isLoadingError && (
            <Typography
              sx={{
                fontFamily: 'Sans400',
                fontSize: isMobile ? '16px' : '20px',
                color: '#E61E42',
                lineHeight: isMobile ? '28px' : '38px',
                textAlign: 'center',
                maxWidth: '392px',
                marginTop: isMobile ? '10px' : '20px',
              }}
            >
              {t('getInTouch.modal.sendMessageError')}
            </Typography>
          )}
        </Box>
      </Box>
    </Fade>
  );
}

export default ThankYouModal;
