export const REVIEWS = [
  {
    image: import('./assets/logo_askgamblers.webp'),
    reviewText: 'reviews.1.text',
    link: 'askgamblers.com',
    name: 'reviews.1.name',
    role: 'reviews.1.role',
  },
  {
    image: import('./assets/logo_lcb.webp'),
    reviewText: 'reviews.2.text',
    link: 'lcb.org',
    name: 'reviews.2.name',
    role: 'reviews.2.role',
  },
  {
    image: import('./assets/logo_casinomeister.webp'),
    reviewText: 'reviews.3.text',
    link: 'casinomeister.com',
    name: 'reviews.3.name',
    role: 'reviews.3.role',
  },
  {
    image: import('./assets/logo_casinofreak.webp'),
    reviewText: 'reviews.4.text',
    link: 'casinofreak.com',
    name: 'reviews.4.name',
    role: 'reviews.4.role',
  },
  {
    image: import('./assets/logo_casinoguru.webp'),
    reviewText: 'reviews.5.text',
    link: 'casino.guru',
    name: 'reviews.5.name',
    role: 'reviews.5.role',
  },
  {
    image: import('./assets/logo_casinomeister.webp'),
    reviewText: 'reviews.6.text',
    link: 'casinomeister.com',
    name: 'reviews.6.name',
    role: 'reviews.6.role',
  },
  {
    image: import('./assets/logo_wisegamblers.webp'),
    reviewText: 'reviews.7.text',
    link: 'wisergamblers.com',
    name: 'reviews.7.name',
    role: 'reviews.7.role',
  },
  {
    image: import('./assets/logo_automatenspieler.webp'),
    reviewText: 'reviews.8.text',
    link: 'automatenspieler.net',
    name: 'reviews.8.name',
    role: 'reviews.8.role',
  },
  {
    image: import('./assets/logo_whichcasino.webp'),
    reviewText: 'reviews.9.text',
    link: 'whichcasino.com',
    name: 'reviews.9.name',
    role: 'reviews.9.role',
  },
];
