import React, { useState } from 'react';

import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import btn_decor from './assets/btn_decor.svg';
import plus from './assets/plus.svg';
import minus from './assets/minus.svg';

const Chapters = {
  GENERAL: 'GENERAL',
  FINANCIAL: 'FINANCIAL',
  TECH: 'TECH',
} as const;

function Question({ question, answer }: { question: string; answer: string }) {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:1000px)');


//   -webkit-tap-highlight-color: transparent;
// -webkit-touch-callout: none;
// -webkit-user-select: none;
// -moz-user-select: none;
// -ms-user-select: none;
// user-select: none;

  return (
    <Box
      id="faq"
      sx={{
        scrollMarginTop: isMobile ? '270px' : '215px',
        borderRadius: '20px',
        border: '1px solid rgba(205, 207, 246, 0.20)',
        padding: '30px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        marginBottom: isMobile ? '10px' : '30px',
        WebkitTapHighlightColor: 'transparent',
        '&:hover': {
          border: '1px solid rgba(205, 207, 246, 0.40)',
        },
      }}
      onClick={() => setOpen((prev) => !prev)}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            color: 'white',
            fontFamily: 'Dela400',
            fontSize: isMobile ? '16px' : '20px',
            lineHeight: 'normal',
            userSelect: 'none',
            marginRight: '20px',
          }}
        >
          {question}
        </Typography>
        <img src={open ? minus : plus} alt="open"></img>
      </Box>
      {open && (
        <Typography
          sx={{
            color: 'rgba(205, 207, 246, 0.70)',
            fontFamily: 'Sans400',
            fontSize: isMobile ? '20px' : '24px',
            lineHeight: isMobile ? '28px' : '38px',
            userSelect: 'none',
            marginTop: isMobile ? '20px' : '30px',
          }}
        >
          {answer}
        </Typography>
      )}
    </Box>
  );
}

function FAQ() {
  const { t } = useTranslation();
  const [selectedChapter, setSelectedChapter] = useState<keyof typeof Chapters>(Chapters.GENERAL);
  const isMobile = useMediaQuery('(max-width:1000px)');
  const generalQuestions = new Array(3).fill(null).map((el, index) => ({
    question: `faq.general.${index + 1}.question`,
    answer: `faq.general.${index + 1}.answer`,
  }));
  const financialQuestions = new Array(5).fill(null).map((el, index) => ({
    question: `faq.financial.${index + 1}.question`,
    answer: `faq.financial.${index + 1}.answer`,
  }));
  const techQuestions = new Array(4).fill(null).map((el, index) => ({
    question: `faq.tech.${index + 1}.question`,
    answer: `faq.tech.${index + 1}.answer`,
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: isMobile ? 0 : '0 30px',
        paddingBottom: isMobile ? '80px' : 0,
        borderBottom: isMobile ? '2px solid rgba(205, 207, 246, 0.10)' : undefined,
      }}
    >
      <Box
        sx={{
          maxWidth: '1216px',
          width: '100%',
          // border: '1px solid red',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontFamily: 'Dela400',
            fontSize: isMobile ? '28px' : '40px',
            color: 'white',
            lineHeight: 'normal',
            marginBottom: isMobile ? '20px' : '60px',
            padding: isMobile ? '0 20px' : undefined,
          }}
        >
          {t('faq.title')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: isMobile ? '30px' : '20px',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <Box
            sx={{
              borderRadius: isMobile ? undefined : '30px',
              minWidth: '269px',
              backgroundColor: isMobile ? undefined : '#202030',
              padding: isMobile ? undefined : '10px',
              display: 'flex',
              flexDirection: isMobile ? 'row' : 'column',
              gap: '10px',
              height: 'max-content',
              overflowX: 'auto',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '& div:first-of-type': {
                marginLeft: isMobile ? '20px' : undefined,
              },
              '& div:last-of-type': {
                marginRight: isMobile ? '20px' : undefined,
              },
            }}
          >
            {Object.values(Chapters).map((chapter) => (
              <Box key={chapter}>
                <Button
                  endIcon={
                    selectedChapter === chapter && !isMobile ? <img src={btn_decor} alt="decor"></img> : undefined
                  }
                  onClick={() => setSelectedChapter(chapter)}
                  sx={{
                    height: isMobile ? '50px' : '85px',
                    borderRadius: isMobile ? '30px' : '20px',
                    background:
                      selectedChapter === chapter
                        ? 'linear-gradient(89deg, #15F1ED 0%, #30FFAA 50.01%, #B1FF31 100%)'
                        : isMobile
                        ? 'rgba(205, 207, 246, 0.10)'
                        : 'transparent',
                    color: selectedChapter === chapter ? '#121221' : 'rgba(205, 207, 246, 0.70)',
                    fontFamily: 'Dela400',
                    fontSize: isMobile ? '14px' : '20px',
                    transition: 'all 0.3s ease',
                    padding: '20px',
                    width: isMobile ? '100%' : '100%',
                    '&:hover': {
                      background:
                        selectedChapter === chapter
                          ? 'linear-gradient(89deg, #15F1ED 0%, #30FFAA 50.01%, #B1FF31 100%)'
                          : 'rgba(205, 207, 246, 0.10)',
                    },
                    '& .MuiButton-endIcon': {
                      position: 'absolute',
                      top: 'calc(50% - 10px)',
                      right: '-4px',
                    },
                  }}
                >
                  {t(`faq.${chapter.toLowerCase()}.title`)}
                </Button>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              padding: isMobile ? '0 20px' : undefined,
              '& >div>div:last-of-type': {
                marginBottom: 0,
              },
            }}
          >
            {selectedChapter === Chapters.GENERAL && (
              <Box>
                {generalQuestions.map((el) => (
                  <Question key={el.question} question={t(el.question)} answer={t(el.answer)}></Question>
                ))}
              </Box>
            )}
            {selectedChapter === Chapters.FINANCIAL && (
              <Box>
                {financialQuestions.map((el) => (
                  <Question key={el.question} question={t(el.question)} answer={t(el.answer)}></Question>
                ))}
              </Box>
            )}
            {selectedChapter === Chapters.TECH && (
              <Box>
                {techQuestions.map((el) => (
                  <Question key={el.question} question={t(el.question)} answer={t(el.answer)}></Question>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FAQ;
