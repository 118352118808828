import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import de from './locales/de.json';
import pt from './locales/pt.json';
import es from './locales/es.json';

const resources = {
    en: {
        translation: en
    },
    de: {
        translation: de
    },
    pt: {
        translation: pt
    },
    es: {
        translation: es
    },
};

i18next
    .use(initReactI18next)
    .init({
        resources: resources,
        lng: 'en',
        fallbackLng: 'en',
        // ns: [
        //     'common',
        //     'quiz',
        //     'quizFitness',
        //     'quizWalking',
        //     'quizMarathon',
        //     'processing',
        //     'plan',
        //     'plan-col',
        //     'payment',
        //     'sign-in',
        //     'manual',
        //     'unsubscribe',
        // ],
        interpolation: {
            escapeValue: false,
        },
    })
    .catch((e) => console.error('[i18n initiation error]: ', e));
